import Footer from "../Footer";
import SuccessPage from "../SuccessPage";


export default function Success() {
    return(
        <>
            <SuccessPage/>
        </>
    );
}