import TermsAndCond from "../Terms";
import Footer from "../Footer";


export default function Terms() {
    return(
        <>
            <TermsAndCond/>
            <Footer/>
        </>
    );
}